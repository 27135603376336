<template>
    <div >
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

      <div class="form-section">
        <filters
          v-if="structure_loaded"
          :filter_data="filter_data"
          :filter_structure="filter_structure"
          :filters="filters"
          @search="setSearchParams"
        >
        </filters>
      </div>
      <div class="modal-footer">
          <a class="close-link" @click="$emit('close')">Cancel</a>
          <button class="w-button primary-btn" @click="generate(this.filters)"><span class="icon"></span> &nbsp;Generate Report</button>
      </div>
    </div>
</template>


<script type="text/babel">
    import Dropdown from '../assets/Dropdown.vue';
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';
    import Filters from '../includes/ReportFunctions/Filters.vue';

    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import MultiSelect from '../assets/MultiSelect.vue';

    export default {
        name: "ReportConfig",
        data() {
            return {
                timeframes: ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Year To Date', 'All Time', 'Custom Range'],
                filters:{},
                filter_structure:{},
                filter_data:{},
                structure:{},
                cols:{},
                structure_loaded: false,

            }
        },
        components:{
            Status,
            Filters,
            Dropdown,
            MultiSelect,
            Datepicker
        },
        async created(){

            let data = await api.get(this, api.REPORTS + 'structure/' + this.report_type);
            this.filters = data.filters;
            this.filter_structure = data.filter_structure;
            this.structure = data.structure;
            this.$set(this.filter_data, 'property_id', this.properties);
            this.filter_structure.map(s => {
                switch (s.type){
                    case 'input':
                    case 'radio':
                    case 'dropdown':
                    case 'date':
                        this.$set(this.filters.search, s.key, this.filters.search[s.key] || '');
                        break;
                    case 'multi-select':
                    case 'list':
                        this.$set(this.filters.search, s.key, this.filters.search[s.key] || []);
                        break;
                    case 'checkbox':
                        this.$set(this.filters.search, s.key, this.filters.search[s.key] || false);
                        break;
                    case 'timeframe':
                        this.$set(this.filters.search, s.key, this.filters.search[s.key] || '');
                        this.$set(this.filters.search, 'timeframe_start', this.filters.search['filter_start'] || '');
                        this.$set(this.filters.search, 'timeframe_end', this.filters.search['filter_end'] || '');
                        break;
                    case 'range':
                        this.$set(this.filters.search, s.key, this.filters.search[s.key] || [0,0]);
                        break;
                    case 'comparison':
                        this.$set(this.filters.search, s.key, this.filters.search[s.key] || {operator: '>', value: '', max: ''});
                        break;
                }
            });


            this.getProperties();

            this.$nextTick(() => {
                this.structure_loaded = true;
            })

        },
        computed: {
            isCustom(){
                return this.filters.search.timeframe && this.filters.search.timeframe.toLowerCase() == 'custom range';
            }
        },
        props: ['products','report_type'],
      //  mixins:[tableKeys],
        methods:{
            generate(){
                this.$emit('generate',this.filters)
            },
            getProperties:  function(){
                api.get(this, api.PROPERTIES + 'list').then(response => {

                    const properties = response.properties.map(p => {
                        return {
                            id: p.id,
                            name: p.address
                        }
                    });
                    this.$set(this.filter_data, 'property_id', properties);
                });
            },
            setSearchParams(filters){this.filters.search = filters;
            }
        }
    }


</script>
