<template>
    <div>
        <form method="POST" action="/reports/save" class="form">
            <div class="container-fluid">
                <div class="col-xs-12 ">
                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>


                    <div class="row lined" v-show="!report.id">
                        <div class="col-xs-12 col-sm-4">
                            <label>I want to:</label>
                        </div>
                        <div class="col-xs-12 col-sm-8" :class="{ 'has-error': errors.has('type')  }">
                            <dropdown
                                v-validate="'required'"
                                :list="reportMeta"
                                v-model="meta"
                                data-vv-as="action"
                                name="type"
                            ></dropdown>

                            <span class="help-block">
                                {{ errors.first('type') }}
                            </span>

                        </div>
                    </div>

                    <div class="row lined" v-show="report.id || meta.length">
                        <div class="col-xs-12 col-sm-4">
                            <label>Report Type </label>
                        </div>
                        <div class="col-xs-12 col-sm-8" :class="{ 'has-error': errors.has('report_type')  }">
                            <dropdown
                                :class="{ 'has-error': errors.has('report_type') }"
                                v-validate="'required'"
                                :list="reportTypeLabels"
                                :value="showReportType"
                                data-vv-as="report type"
                                @input="setReportType"
                                hasEmpty>

                            </dropdown>
                             <span class="help-block">
                                {{ errors.first('report_type') }}
                            </span>
                        </div>
                    </div>

                    <div class="row lined" v-show="report.report_name == 'Billing Report'">
                        <div class="col-xs-12 col-sm-4">
                            <label>Choose Products</label>
                        </div>
                        <div class="col-xs-12 col-sm-8">
                            <auto-complete
                                :list="productNames"
                                placeholder="Choose a Product"
                                :selection="product.name"
                                @change="setProduct"
                                @input="searchProducts"
                                name="product_id"
                                showempty
                            >
                            </auto-complete>
                            <div class="chosen-params">
                                <div class="param" v-for="(p, i) in chosenProducts">
                                    {{p.name}}
                                    <i class="fa fa-remove" @click="removeProduct(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row lined" v-show="!report.id && (meta == reportMeta[0])" :class="{'has-error': errors.has('start_date') || errors.has('end_date')  }">
                        <div class="col-xs-12 col-sm-4">
                            <label>Report Start / End</label>
                        </div>
                        <div class="col-xs-6 col-sm-4">
                            <div class="input-group">
                                <datepicker
                                        v-model="report.start"
                                        data-vv-as="report start date"
                                        format="MM/dd/yyyy"
                                        placeholder="Start Date"
                                        name="start_date"
                                        input-class="form-control"
                                        wrapper-class="datepicker-wrapper"
                                        v-validate=" meta == reportMeta[0] ? 'required': ''"
                                >


                                </datepicker>
                                <div class="input-group-addon subdued">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-4">
                            <div class="input-group">
                                <datepicker
                                    v-model="report.end"
                                    format="MM/dd/yyyy"
                                    placeholder="End Date"
                                    input-class="form-control"
                                    wrapper-class="datepicker-wrapper"
                                    data-vv-as="report end date"
                                    name="end_date"
                                    v-validate=" meta == reportMeta[0]? 'required': ''"
                                ></datepicker>
                                <div class="input-group-addon subdued">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                        </div>

                        <span class="col-xs-12 col-sm-8 col-sm-push-4 help-block">
                            {{ errors.first('start_date') }} {{ errors.first('end_date') }}
                        </span>
                    </div>


                    <div class="row lined" v-show="report.id || meta == reportMeta[1]">
                        <div class="col-xs-12 col-sm-4">
                            <label>Report Frequency</label>
                        </div>
                        <div class="col-xs-12 col-sm-8">
                            <dropdown
                                :list="timePeriods"
                                :value="report.time_period | capitalize"
                                @input="setTimePeriod"
                                hasEmpty
                            ></dropdown>
                        </div>
                    </div>

                    <div class="row lined" v-show="report.time_period && report.time_period.toLowerCase() == 'weekly' && meta == reportMeta[1] ">
                        <div class="col-xs-12 col-sm-4">
                            <label>Send on what day of the week?</label>
                        </div>
                        <div class="col-xs-12 col-sm-8">
                            <dropdown :list="daysOfWeek" :value="report.send_day" @input="setSendDay" hasEmpty></dropdown>

                        </div>
                    </div>
                    <div class="row lined" v-show="report.time_period && report.time_period.toLowerCase() == 'monthly' && meta == reportMeta[1]  ">
                        <div class="col-xs-12 col-sm-4">
                            <label>Send on what day of the month?</label>
                        </div>
                        <div class="col-xs-12 col-sm-8">
                            <dropdown :list="daysOfMonth" :value="report.send_day" @input="setSendDay" hasEmpty></dropdown>

                        </div>
                    </div>



                    <div class="row lined"  v-show="report.id || meta.length  ">
                        <div class="col-xs-12 col-sm-4">
                            <label>Emails Addresses</label>
                        </div>
                        <div class="col-xs-12 col-sm-8">
                            <input
                                type="text"
                                placeholder="Enter emails"
                                class="form-control"
                                @change.prevent="updateEmail"
                                v-model.lazy="send_address"

                            >
                            <div class="help-block error" v-show="email_error">{{email_error}}</div>

                            <div class="chosen-params">
                                <span class="param space" v-for="email in report.send_to">{{email}} <i @click="removeEmail(email)" class="fa fa-remove"></i></span>
                            </div>

                        </div>
                    </div>






                </div>
            </div>

        </form>
        <div class="modal-footer">
            <button class="btn btn-dark" @click="save">Save</button>
            <button class="btn btn-std" @click="$emit('close')">Cancel</button>
        </div>

    </div>
</template>
<script type="text/babel">

    // import InputTag from 'vue-input-tag';
    import Dropdown from '../assets/Dropdown.vue';
    import Modal from '../assets/Modal.vue';
    var s = require("underscore.string");
    import AutoComplete from '../assets/Autocomplete.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "EditReport",
        data() {
            return {
                open: '',
                meta: '',
                report:{
                    one_time: false,
                    report_id: null,
                    report_type: null,
                    send_day: null,
                    time_period: null,
                    data: '',
                    send_to: [],
                    start: null,
                    end: null
                },
                send_address:'',
                reportTypes: [
                ],
                sendDayOptions:[],
                timePeriods:['Daily', 'Weekly', "Monthly", "Custom"],
                reportMeta: ['Run a report now', "Create a recurring report"],
                daysOfWeek: ['Monday', 'Tuesday', "Wednesday","Thursday", "Friday", "Saturday", "Sunday"],
                products:[],
                chosenProducts: [],
                product:{
                    name: '',
                    id: ''
                },
                email_error: null


            }
        },
        components:{
            Dropdown,
            // InputTag,
            Modal,
            AutoComplete,
            Datepicker,
            Status
        },
        props:[
            'selected'
        ],
        filters:{


        },
        created(){

            api.get(this, api.REPORTS).then(r => {
                this.reportTypes = r.reportTypes.map(type => {
                    return {
                        label: type.name,
                        value: type.id
                    }
                });
                if(this.selected.id){
                    this.report = JSON.parse(JSON.stringify(this.selected));
                    this.report.send_to = JSON.parse(this.report.send_to);

                    switch(this.report.report_name){
                        case 'Billing Report':
                            this.chosenProducts = JSON.parse(this.report.data);
                    }

                }
            });
        },
        computed:{
            showReportType(){
                var _this = this;

                return this.reportTypes.filter(function(type){
                    return type.value == _this.report.report_id;
                }).map(function(type){
                    return type.label;
                })[0];
            },
            openSuggestion () {
                return this.open === true;
            },
            reportTypeLabels (){
                return  this.reportTypes.map(function(type){
                    return type.label;
                })
            },
            daysOfMonth(){
                var days = [];

                for (var i = 1; i <= 31; i++) {
                    days.push(i.toString());
                }
                return days;
            },
            productNames(){
                return this.products.map(function(p){ return p.name });
            }
        },

        methods:{
            removeProduct(p){
                this.chosenProducts.splice(p, 1);
            },
            searchProducts(search){

                this.product.name = search;

                var params = {
                    type: 'product',
                    all: true,
                    search: search
                };

                api.get(this, api.SEARCH_PRODUCTS, params).then(r => {
                    this.products = r.products;
                });

            },
            setProduct(name){

                if(!name.length) {
                    this.product = {
                        name: '',
                        id: ''
                    }
                    return;
                }


                var product = this.products.filter(function(p){ return p.name == name; })[0];

                var existing = this.chosenProducts.filter( p => { return p.id == product.id });

                if(existing.length) return;

                this.chosenProducts.push({
                    id: product.id,
                    name: product.name
                });

                this.product = {
                    name: '',
                    id: ''
                }


            },
            setReportType(reportType){

                this.report.report_id = this.reportTypes.filter(function(type){
                    return type.label == reportType;
                }).map(function(type){
                    return type.value;

                })[0];

                this.report.report_name = reportType;

            },
            updateEmail(){
                this.email_error = null;
                if(!this.send_address.length) return;


                var re = /\S+@\S+\.\S+/;
                if(!re.test(this.send_address)){
                    this.email_error = "Please enter a valid email address";
                    return;
                }

                this.report.send_to.push(this.send_address);
                this.send_address = '';
            },

            removeEmail(email){
                this.report.send_to.splice(this.report.send_to.indexOf(email), 1);

            },

            setTimePeriod(value){

                this.report.time_period = value;
                this.report.send_day = null;


            },
            setSendDay(value){

                this.report.send_day = value;
            },

            save(){
                var _this = this;
                _this.errorClear(_this.$options.name);
                this.$validator.validateAll().then(function(status){
                    if (!status) {
                        _this.errorSet(_this.$options.name, "You have errors on your form.  Please fix them before continuing");

                        return;
                    }

                    if( !_this.report.send_to.length ){
                        _this.errorSet(_this.$options.name, "Please enter at least one email address to send this report to.");

                        return;
                    }


                    if( _this.report.report_name == 'Billing Report' && !_this.chosenProducts.length ){
                        _this.errorSet(_this.$options.name, "Please enter a product to populate the report with.");
                        return;
                    }



                    if(_this.meta == _this.reportMeta[0]) {
                        _this.report.one_time = true;
                        _this.report.start = moment(_this.report.start).startOf('day').format('YYYY-MM-DD');
                        _this.report.end = moment(_this.report.end).startOf('day').format('YYYY-MM-DD');
                    }

                    switch(_this.report.report_name){
                        case 'Billing Report':
                            _this.report.data = _this.chosenProducts;
                            break;
                        default:
                            _this.report.data = '';
                    }

                    api.post(_this, api.SAVE_REPORT, _this.report).then(r => {

                        if(_this.meta == _this.reportMeta[1]){
                            _this.$emit('refetch');
                            _this.$emit('close');
                        }
                        _this.resetForm();

                    });

                });



            },


            away(){
                return this.openSuggestion = false;
            },
            resetForm(){
                this.report = {
                    one_time: false,
                    report_id: null,
                    report_type: null,
                    send_day: null,
                    time_period: null,
                    data: '',
                    send_to: [],
                    start: null,
                    end: null
                }
            }

        },

    }


</script>
<style>
    .autocomplete-holder{
        display: inline-block;
        width: 200px;
        vertical-align: top;
    }
</style>
