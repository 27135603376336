<template>
  <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
    <hb-header full :divider="false">
        <hb-page-header
          title="Reports"
          description="View all your reports here."
        >
        </hb-page-header>
    </hb-header>
    <hb-header
      fewer-actions
      :padding="false"
      align-top
      class="mt-n2"
    >
        <template v-slot:left>
            <hb-tabs
              v-model="activeTab"
            >
              <v-tab
                v-for="menuOption in menuOptions"
                :key="menuOption.key"
                :href="'#tab-' + menuOption.key"
                @click="view = menuOption.key"
                :ripple="false"
              >{{ menuOption.label }}</v-tab>
            </hb-tabs>
        </template>
        <!-- Legacy Icons -->
        <!-- <template v-slot:right>
            <div class="d-flex align-center ml-2">
                <dropdown-menu width="350" ref="review_charges" align="left">
                  <template slot="trigger">
                    <hb-btn class="ml-1" icon tooltip="Review Charges" active-state-off>mdi-financial-custom-1</hb-btn>
                  </template>
                  <template slot="dropdown" slot-scope="props">
                    <div style="padding: 20px;">
                      <status
                        @resetStatus="errorClear('review_charges')"
                        v-if="errorHas('review_charges')"
                        :message="errorGet('review_charges')"
                        status="error"
                      ></status>
                      <status
                        @resetStatus="successClear('review_charges')"
                        v-if="successHas('review_charges')"
                        :message="successGet('review_charges')"
                        status="success"
                      ></status>

                      <div class="form-section">
                        <strong>Sending Charges list</strong>
                        <br />
                        <br />
                        <p>Choose the billing day you would like to receive a charges report for.</p>

                        <br />

                        <datepicker
                          v-validate="'required'"
                          :class="{'has-error': errors.has('review_charges') }"
                          name="review_charges"
                          id="review_charges"
                          ref="review_charges"
                          data-vv-as="date"
                          v-model="reviewBillDate"
                          format="MM/dd/yyyy"
                          style="width: 100%"
                          wrapper-class="date-wrapper"
                          input-class="input w-input date-input light"
                        ></datepicker>
                      </div>

                      <div class="modal-footer d-flex align-center justify-end">
                        <a @click="props.away()" class="mr-2">Close</a>
                        <hb-btn @click="reviewInvoices" color="primary">Review Invoices</hb-btn>
                      </div>
                    </div>
                  </template>
                </dropdown-menu>
                <hb-btn class="ml-1" icon tooltip="Send Statement to Tenants" @click="sendInvoices = true" :active="sendInvoices">mdi-email-send</hb-btn>
            </div>
        </template> -->
    </hb-header>

    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

    <div class="primary-section-content">
      <v-sheet class="grey lighten-5" :class="{'mr-5' : showAside}" style="width: calc(100%);">
        
        <MyReports v-if="savedReports && view === 'myreports'" v-bind:savedReports.sync="savedReports" :goToReport="goToReport" :deleteItem="deleteItem" :downloadXLSX="downloadXLSX" :downloadPDF="downloadPDF"></MyReports>
        <ReportLibrary
          v-if="savedReports && view === 'reportlibrary'"
          :savedReports="cannedReports"
        ></ReportLibrary>
        <SummaryReports v-if="view === 'summaryreports'"></SummaryReports>
      </v-sheet>
<!--      <v-navigation-drawer-->
<!--        v-model="showAside"-->
<!--        right-->
<!--        hide-overlay-->
<!--        clipped-->
<!--        class="ma-0 pa-0 py-2 mr-1 hb-tertiary-navigation"-->
<!--        style="height:auto;"-->
<!--        color="grey lighten-5"-->
<!--        :width="showAside?'272px':'0px'"-->
<!--        disable-resize-watcher-->
<!--        mobile-breakpoint="1"-->
<!--        flat-->
<!--      >-->
<!--        <v-list dense>-->
<!--          <v-list-item-group v-model="selection">-->
<!--            <div class="pl-7 pt-3 pb-2 font-weight-medium hb-text-night">-->
<!--              Reports-->
<!--            </div>-->
<!--            <v-list-item class="pl-7" to="/reports/tenants">-->
<!--              Tenant List-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/units">-->
<!--              Unit List-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/leads">-->
<!--              Leads-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/invoices">-->
<!--              Invoices-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/invoice-lines">-->
<!--              Charges-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/invoices-payments">-->
<!--              Payments-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/failed-payments">-->
<!--              Failed Payments-->
<!--            </v-list-item>-->
<!--            <v-list-item class="pl-7" to="/reports/payment-methods">-->
<!--              Payment Methods-->
<!--            </v-list-item>-->
<!--          </v-list-item-group>-->
<!--        </v-list>-->
<!--      </v-navigation-drawer>-->
    </div>
    <modal v-if="showAdd" size="lg" allow-click-away="false" @close="closeWindow">
      <h3 slot="header">Add Report</h3>
      <edit-report
        size="lg"
        slot="body"
        :selected="selected"
        @close="closeWindow"
        @refetch="fetchData"
      ></edit-report>
    </modal>

    <modal v-if="showReportConfigWindow" size="lg" allow-click-away="false" @close="closeWindow">
      <h3 slot="header">Configure Report</h3>

      <report-config
        slot="body"
        v-if="report_type"
        @generate="generate"
        @close="closeWindow"
        :report_type="report_type"
        :properties="properties"
        :products="products"
      ></report-config>
    </modal>

    <modal v-if="showEdit" size="lg" allow-click-away="false" @close="closeWindow">
      <h3 slot="header">Edit Report</h3>
      <edit-report
        size="lg"
        slot="body"
        :selected="selected"
        @close="closeWindow"
        @refetch="fetchData"
      ></edit-report>
    </modal>

    <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" title="Delete Report" @close="closeWindow" confirmation>
      <template v-slot:content>
          <div class="py-4 px-6">
              Are you sure you want to delete the selected report?
              <br /><br />
              This action cannot be undone.
          </div>
      </template>
      <template v-slot:actions>
          <hb-btn color="destructive" @click="deleteConfirm">Delete</hb-btn>
      </template>
    </hb-modal>

    <hb-modal
      size="medium"
      title="Send Invoices To Tenants"
      v-model="sendInvoices"
      v-if="sendInvoices"
      :persistent="false"
      @close="enlargeHeight = false"
      show-help-link
    >
      <template v-slot:subheader>
        <hb-notification type="caution" not-dismissable footer-off>This will email invoices to all customers!</hb-notification>
      </template>
      <template v-slot:content>
        <div class="form-row pa-6" :style="enlargeHeight ? 'height:305px;' : 'height:auto;'">
            <div class="pb-3">Choose the bill date that you would like to send invoices out for:</div>

            <div class="form-container">
              <div class="form-input" style="width: 200px;" @click="enlargeHeight = true">
                <datepicker
                  v-validate="'required'"
                  :class="{'has-error': errors.has('send_charges') }"
                  name="send_charges"
                  id="send_charges"
                  ref="send_charges"
                  data-vv-as="date"
                  v-model="sendBillDate"
                  @opened="enlargeHeight = true"
                  format="MM/dd/yyyy"
                  style="width: 100%"
                  wrapper-class="date-wrapper"
                  input-class="input w-input date-input light"
                ></datepicker>
              </div>
            </div>
          </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="emailInvoices">
          Send Invoices
        </hb-btn>
      </template>
    </hb-modal>

    <v-overlay :value="reportLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script type="text/babel">
import Modal from "../assets/Modal.vue";
import EditReport from "./EditReport.vue";
import moment from "moment";

import Status from "../includes/Messages.vue";
import Loader from "../assets/CircleSpinner.vue";

import Dropdown from "../assets/Dropdown.vue";
import ReportConfig from "./ReportConfig.vue";
import MyReports from "./MyReports";
import SummaryReports from "./SummaryReports";
import ReportLibrary from "./ReportLibrary";
import DropdownMenu from "../assets/DropdownMenu.vue";
import Datepicker from "vuejs-datepicker";
import api from "../../assets/api.js";
import MultiSelect from "../assets/MultiSelect.vue";
import Header from "../header/Index.vue";
import { tableKeys } from "../../mixins/tableColumns/generic_report.js";
import { tableFilters } from "../../mixins/urlParser.js";

export default {
  name: "ReportsIndex",
  data() {
    return {
      view: "reportlibrary",
      activeTab: "tab-reportlibrary",
      menuOptions: [
        {
          key: "myreports",
          label: "My Reports"
        },
        {
          key: "reportlibrary",
          label: "Dynamic Reports"
        },
        {
          key: "summaryreports",
          label: "Static Reports"
        }
      ],
      savedReports: [],
      cannedReports: [],
      reports: [],
      selected: {},
      showEdit: false,
      showDelete: false,
      showAdd: false,
      showAside: false,
      reviewBillDate: "",
      sendBillDate: "",
      sendInvoices: false,
      report_type: "",
      showReportConfigWindow: false,
      properties: [],
      products: [],
      timeframes: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Year To Date",
        "All Time",
        "Custom Range"
      ],
      reportLoading: false,
      enlargeHeight: false,
      selection: ''
    };
  },
  components: {
    Modal,
    EditReport,
    DropdownMenu,
    Datepicker,
    Dropdown,
    Status,
    Loader,
    MultiSelect,
    ReportConfig,
    MyReports,
    ReportLibrary,
    SummaryReports,
    Header
  },
  created() {
    this.fetchData();
    this.fetchProducts();
    this.getProperties();
    this.getSavedReports();
    this.getCannedReports();
  },
  computed: {
    isCustom() {
      return this.filters.search.timeframe.toLowerCase() == "custom range";
    }
  },
  filters: {
    displayRecipients(list) {
      return JSON.parse(list).join(", ");
    },
    displayTimePeriod(report) {
      switch (report.time_period) {
        case "weekly":
          return "Weekly on " + report.send_day;
          break;
        case "monthly":
          return "Monthly on the " + moment(report.send_day, "D").format("Do");
          break;
        case "daily":
          return "Daily";
          break;
      }
    }
  },
  methods: {
    toggleSideBar() {
      this.showAside = !this.showAside;
    },
    goToReport(report) {

      try {
        this.$router.push("reports/view/" + report.id);
      } catch (err) {}
    },
    getSavedReports() {
      api.get(this, api.REPORTS + "saved").then(r => {
        this.savedReports = Object.assign([], r.savedReports);
      });
    },
    getCannedReports() {
      api.get(this, api.REPORTS + "canned").then(r => {
        this.cannedReports = r.cannedReports;
      });
    },

    yardi() {
      this.report_type = "yardi";
      this.generate({
        search: {
          timeframe: "yesterday"
        }
      });
    },
    fetchProducts() {
      api
        .post(this, api.PRODUCTS + "search", {
          types: ["product", "insurance", "late", "rent"]
        })
        .then(r => {
          this.products = r.products;
        });
    },
    getProperties: function() {
      api.get(this, api.PROPERTIES + "list").then(response => {
        this.properties = response.properties;
      });
    },
    closeWindow() {
      this.showEdit = false;
      this.showDelete = false;
      this.showAdd = false;
      this.showReportConfigWindow = false;
      this.selected = {};
    },
    fetchData() {
      api.get(this, api.AUTO_REPORTS).then(r => {
        this.reports = r.reports;
      });
    },
    editItem(u) {
      this.selected = u;
      this.showEdit = true;
    },
    deleteItem(u) {
      this.selected = u;
      this.showDelete = true;
    },
    deleteConfirm() {
      api.delete(this, api.REPORTS, this.selected.id).then(r => {
        this.showDelete = false;
        this.getSavedReports();
      });
    },

    downloadXLSX(report) {
      this.generate(
        JSON.parse(report.filters),
        "excel",
        report.template,
        report.name
      );
    },

    downloadPDF(report) {
      var data = {
        filters: report.filters,
        type: report.template,
        format: 'web',
        name:  report.name,
        is_generic: true
      };
      this.reportLoading = true
      this.$http
        .post(api.REPORTS + "download-pdf", data, { responseType: "arraybuffer" })
        .then(function(response) {
            var blob = new Blob([response.data], {
              type: response.headers.get("content-type")
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =  report.name + '_' + moment().format('x')
            link.click();
            this.report_type = null;
            this.closeWindow();
            this.reportLoading = false
            this.successSet(this.$options.name, "Your download should begin shortly.");

        }).catch(err => {
          this.reportLoading = false
          this.errorSet(this.$options.name, 'We could not process your request. Please try again later');
        });
    },

    generate(filters, format, report_type, name) {
      var data = {
        filters: filters,
        type: report_type,
        format: format,
        name: name
      };

      this.$http
        .post(api.REPORTS + "generate", data, { responseType: "arraybuffer" })
        .then(function(response) {
          //var headers = response.headers();
          var blob = new Blob([response.data], {
            type: response.headers.get("content-type")
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
          this.report_type = null;
          this.closeWindow();
        });
    },

    reviewInvoices() {
      if (!this.reviewBillDate) {
        this.errorSet("review_charges", "Please choose a billing date");
      } else {
        api
          .post(
            this,
            api.SEND_INVOICE_REVIEW,
            { date: moment(this.reviewBillDate).format("YYYY-MM-DD") },
            "review_charges"
          )
          .then(r => {
            this.successSet(
              "review_charges",
              "Invoice Review Sent to your email address"
            );
          });
      }
    },

    emailInvoices() {
      if (!this.sendBillDate) {
        this.errorSet(this.$options.name, "Please choose a billing date");
      } else {
        api
          .post(this, api.SEND_INVOICES_TO_TENANTS, {
            date: moment(this.sendBillDate).format("YYYY-MM-DD")
          })
          .then(r => {
            this.successSet(
              this.$options.name,
              "Invoices have been queued to send to your tenants"
            );
          })
          .finally(() => {
            this.sendInvoices = false;
          });
      }
    },
    showReportConfig(type) {
      this.report_type = type;
      this.showReportConfigWindow = true;
    }
  },
  mixins: [tableKeys, tableFilters]
};
</script>
<style scoped lang="scss">
.section-content{
  background:rgb(249, 250, 251);
}
.custom-border-bottom {
  border-bottom: 1px solid lightgray;
}
.primary-section-content {
  display: flex;
  height: calc(100% - 70px);
}
.attached-drawer {
  height: auto;
  padding: 0;
  width: 0;

  &.theme--light {
    &.v-navigation-drawer {
      background-color: none;
    }
  }
  &.attached-drawer--active {
    width: 400px;
  }
}

.mr--20 {
}
.no-drag {
  background: rgba(0, 0, 0, 0.1);
}
.dashboard-options {
  cursor: grab;
  .no-drag {
    cursor: not-allowed;
  }
}
.subHeading {
  font-size: 14px;
  vertical-align: top;
  letter-spacing: -0.2px;
  align-content: left;
  margin: 0px;
}
.main-section-heading {
  padding: 29px 20px 0px 0px !important;
  border: none !important;
}
</style>
