<template>
  <v-card class="mt-5" elevation="1">

    <v-data-table
      :headers="headers"
      :items="savedReports"
      class="hb-data-table"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.name="{ item }">
        <span class="font-weight-medium">{{ item.name }}</span><br />

      </template>
      <template v-slot:item.description="{ item }">
        <span>{{ item.description }}</span>

      </template>
      <template v-slot:item.id="{ item }">
        <hb-menu
          options
          align-right
        >
          <v-list>
            <v-list-item @click="goToReport(item)">
              <v-list-item-title>View Report</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>Delete Report</v-list-item-title>
            </v-list-item>
          </v-list>
        </hb-menu>

      </template>
    </v-data-table>

  </v-card>
</template>
<script type="text/babel">

export default {
  name: "MyReports",
  data() {
    return {
      columnDefs: null,
      rowData: null,
      left: 200,
      top: 200,
      reportData: {},
      openDropdown: false,
      headers: [
          { text: 'Report Name', align: 'start', value: 'name' },
          { text: 'Description', align: 'start', value: 'description' },
          { text: 'Actions', align: 'end', value: 'id' }
      ]
    };
  },
  components: {

  },
  beforeMount() {
   // this.columns = this.setColumnDefinition(this.columns);
    this.rowData = this.savedReports;
  },
  methods: {

  },
  computed: {

    report: function() {
      return this.reportData;
    }
  },
  created: function() {
    let self = this;
  },
  watch: {
    savedReports() {
      this.rowData = this.savedReports;
    }
  },
  props: {
    savedReports: {
      type: Array,
      required: true
    },
    goToReport: {
      type: Function,
      required: true
    },
    deleteItem: {
      type: Function,
      required: true
    },
    downloadXLSX: {
      type: Function,
      required: true
    },
    downloadPDF: {
      type: Function,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown {
  position: absolute;
  display: inline-block;
  right: 0.4em;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 40px;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: visible;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .my-menu-option {
  color: #101318;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  display: block;
}

.my-menu-option:hover {
  background-color: #f2f2f2;
}

.show {
  display: block;
}
</style>
