
export const tableKeys = {
	data() {
		return {
			cols: [],
			filters:{
				search:{
					property_id: [],
					timeframe: 'Last 7 Days',
					start_date: '',
					end_date: ''
				},
				columns:[],
				sort:{
					field: 'id',
					dir: 'DESC'
				},
				page: 1,
				limit:25,
				offset:0
			},
		}
	}
};